<template>
  <div v-if="isshowgrid">
    <va-card title="service category List">
      <template slot="actions">
        <va-button color="success" v-if='addFlag' icon="fa fa-plus" @click="add()">Add</va-button>
      </template>
      <div class="row align--center">
        <div class="flex xs12 md6">
          <va-input class="va-input-search-container"
            :value="term"
            placeholder="search"
            @input="search"
            removable
          >
            <va-icon name="fa fa-search" slot="prepend" />
          </va-input>
        </div>
      </div>
      <va-data-table
        :fields="fields"
        :data="filteredData"
        :per-page="parseInt(perPage)">
        <template slot="actions" slot-scope="props">
          <va-button flat small color="gray" icon="fa fa-pencil" @click="edit(props.rowData)" class="ma-0"></va-button>
        </template>
      </va-data-table>
    </va-card>
  </div>
  <div v-else-if='isshowForm'>
    <div>
      <va-card v-if="isshowForm" :title="title">
        <template slot="actions">
          <va-button color="gray" @click="list()">List</va-button>
        </template>
        <div>
          <form>
            <span class="va-form-label va-form-required-label">Category Type</span>
            <va-input
              v-model.trim="form_data.type"
              placeholder="Enter Category Type"
              :error="!!error_msg.typeErrors.length"
              :error-messages="error_msg.typeErrors"
            />
            <span class="va-form-label va-form-required-label">Category Short Code</span>
            <va-input
              v-model.trim="form_data.short_code"
              placeholder="Enter Category Short Code"
              :error="!!error_msg.codeErrors.length"
              :error-messages="error_msg.codeErrors"
            />
            <span class="va-form-label">Filter</span>
            <va-select
              v-model="form_data.filter"
              textBy="id"
              placeholder="Select Filter"
              :options="filterArr"
              :error="!!error_msg.FilterErrors.length"
              :error-messages="error_msg.FilterErrors"
            />
            <span class="va-form-label">Sort By</span>
            <va-select
              v-model="form_data.sort_by"
              textBy="id"
              placeholder="Select Sort By"
              :options="sortArr"
              :error="!!error_msg.sortByErrors.length"
              :error-messages="error_msg.sortByErrors"
            />
            <div class="d-flex justify--end mt-3">
              <va-button type="submit" class="my-0 va-button-normal-cancel" @click.prevent="list()">Cancel</va-button>
              <va-button v-if="isCreate" type="submit" class="my-0" @click.prevent="createConfig()">Create</va-button>
              <va-button v-if="isUpdate" type="submit" class="my-0" @click.prevent="updateConfig()">Update</va-button>
            </div>
          </form>
        </div>
      </va-card>
    </div>
  </div>
</template>
<script>
import { debounce } from 'lodash'
import Vue from 'vue'
import vueResource from 'vue-resource'
import { search } from '../../i18n/search.js'
import config from '../../i18n/en.json'
Vue.use(vueResource)

export default {
  name: 'service_category',
  data () {
    return {
      term: null,
      perPage: 10,
      totalPages: 0,
      items: [],
      loading: false,
      message: '',
      category_id: '',
      form_data: {
        type: '',
        short_code: '',
        filter: '',
        sort_by: '',
      },
      error_msg: {
        option: true,
        typeErrors: [],
        codeErrors: [],
        FilterErrors: [],
        sortByErrors: [],
      },
      perPageOptions: ['4', '6', '10', '20'],
      isshowgrid: false,
      isshowForm: false,
      isCreate: false,
      isUpdate: false,
      title: '',
      sortArr: [{ id: 'Ascending' }, { id: 'Decending' }],
      filterArr: [{ id: 'Rating' }, { id: 'Watchcount' }, { id: 'Releasedate' }],
      addFlag: false,
    }
  },
  computed: {
    formReady () {
      return !this.error_msg.typeErrors.length && !this.error_msg.codeErrors.length
    },
    fields () {
      return [{
        name: 'sno',
        title: 'S.NO',
        width: '6%',
      }, {
        name: '__slot:marker',
        width: '30px',
        dataClass: 'text-center',
      },
      {
        name: 'type',
        title: 'Category Type',
      },
      {
        name: 'short_code',
        title: 'Short Code',
      },
      {
        name: '__slot:actions',
        dataClass: 'text-right',
      }]
    },
    filteredData () {
      return search(this.term, this.items)
    },
  },
  created () {
    this.readItems()
  },
  methods: {
    readItems (page = 0) {
      this.loading = true
      this.isshowForm = false
      this.isshowgrid = true
      var role = Vue.$cookies.get('userRole')
      this.addFlag = (role == 'ADMIN') ? true : false
      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.get(config.menu.host + '/servicecategory').then(response => {
        loader.hide()
        let index = 0
        this.items = response.body.map(function (item) {
          item.id = index++
          item.sno = index
          item.is_existing = true
          item.checkbox_value = false
          return item
        })
        this.loading = false
        this.isshowgrid = true
      })
    },
    createConfig () {
      this.reset()
      this.error_msg.codeErrors = this.form_data.short_code ? [] : ['Short code is required']
      this.error_msg.typeErrors = this.form_data.type ? [] : ['Type is required']

      if (!this.formReady) {
        return
      }
      var payload = {
        type: this.form_data.type,
        short_code: this.form_data.short_code,
        filter: this.form_data.filter.id,
        sort_by: this.form_data.sort_by.id,
      }
      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.post(config.menu.host + '/servicecategory', payload).then(responseData => {
        loader.hide()
        if (responseData && responseData.body) {
          Vue.notify({ text: responseData.body, type: 'success' })
        }
        this.list()
      }, errResponse => {
        loader.hide()
        if (errResponse && errResponse.body) {
          Vue.notify({ text: errResponse.body, type: 'error' })
        }
      })
    },
    updateConfig () {
      this.reset()
      this.error_msg.codeErrors = this.form_data.short_code ? [] : ['Short code is required']
      this.error_msg.typeErrors = this.form_data.type ? [] : ['Type is required']

      if (!this.formReady) {
        return
      }
      var payload = {
        type: this.form_data.type,
        short_code: this.form_data.short_code,
        filter: this.form_data.filter.id ? this.form_data.filter.id : this.form_data.filter,
        sort_by: this.form_data.sort_by.id ? this.form_data.sort_by.id : this.form_data.sort_by,
      }
      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.put(config.menu.host + '/servicecategory/' + this.category_id, payload).then(responseData => {
        loader.hide()
        if (responseData && responseData.body) {
          Vue.notify({ text: responseData.body, type: 'success' })
        }
        this.list()
      }, errResponse => {
        loader.hide()
        if (errResponse && errResponse.body) {
          Vue.notify({ text: errResponse.body, type: 'error' })
        }
      })
    },
    add () {
      this.title = ' Create service category'
      this.form_data.type = ''
      this.form_data.short_code = ''
      this.form_data.filter = ''
      this.form_data.sort_by = ''
      this.isshowForm = true
      this.isshowgrid = false
      this.isUpdate = false
      this.isCreate = true
    },
    edit (row) {
      this.title = ' update service category'
      this.form_data.type = row.type
      this.form_data.short_code = row.short_code
      this.form_data.filter = row.filter ? row.filter : ''
      this.form_data.sort_by = row.sort_by ? row.sort_by : ''
      this.category_id = row.category_id
      this.isshowgrid = false
      this.isshowForm = true
      this.isCreate = false
      this.isUpdate = true
    },
    reset () {
      this.error_msg.typeErrors = []
      this.error_msg.codeErrors = []
    },
    list () {
      this.isshowForm = false
      this.isshowgrid = true
      this.readItems()
      this.error_msg.typeErrors = []
      this.error_msg.codeErrors = []
    },
    search: debounce(function (term) {
      this.term = term
    }, 400),
  },
}
</script>
<style lang="scss">
.radio_options {
  display: flex;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
</style>
